import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "vstack--hstack"
    }}>{`VStack & HStack`}</h1>
    <p>{`A stack groups content vertically (VStack) or horizontally (HStack). A stack can also determine the order of overlapping content (ZStack). Stacks are commonly nested to create layouts.`}</p>
    <p>{`By default, `}<inlineCode parentName="p">{`VStack`}</inlineCode>{` and `}<inlineCode parentName="p">{`HStack`}</inlineCode>{` align to the middle and center of the containing view.
You can specify a different `}<inlineCode parentName="p">{`alignment`}</inlineCode>{` property such as `}<inlineCode parentName="p">{`.leading`}</inlineCode>{` or `}<inlineCode parentName="p">{`.trailing`}</inlineCode>{` for `}<inlineCode parentName="p">{`VStack`}</inlineCode>{`.
`}<inlineCode parentName="p">{`HStack`}</inlineCode>{` takes `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/verticalalignment"
      }}>{`different alignment params`}</a>{`, but
these are rarely used. `}</p>
    <p>{`You can also pass a `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` param to apply an equal amount of space between each
view (element) in the stack. `}</p>
    <ContentBlock imgName="Stacks-VStackHStack.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    @frozen struct VStack<Content> where Content : View
    init(alignment: HorizontalAlignment = .center, spacing: CGFloat? = nil, @ViewBuilder content: () -> Content)\n
    @frozen struct HStack<Content> where Content : View
    init(alignment: VerticalAlignment = .center, spacing: CGFloat? = nil, @ViewBuilder content: () -> Content)\n
    Outer VStack: blue
    Inner stacks: yellow
    Text views: red
*/
VStack(spacing: 60) {
    VStack(spacing: 15) {
        Text("I have some spacing between here").border(Color.red)
        Text("and here").border(Color.red)
    }
    .padding()
    .border(Color.yellow, width: 3)\n
    HStack() {
        Text("I am").border(Color.red)
        Text("centered horizontally").border(Color.red)
        Text("in an").border(Color.red)
        Text("HStack").border(Color.red)
    }
    .padding()
    .border(Color.yellow, width: 3)\n
    VStack(alignment: .leading) {
        Text("I am aligned").border(Color.red)
        Text("to the left within this VStack").border(Color.red)
    }
    .padding()
    .border(Color.yellow, width: 3)\n
    VStack(alignment: .trailing) {
        Text("I am aligned").border(Color.red)
        Text("to the right within this VStack").border(Color.red)
    }
    .padding()
    .border(Color.yellow, width: 3)
}
.font(.headline)
.padding()
.border(Color.blue, width: 3)
    `}
  </code>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      